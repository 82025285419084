<template>
  <SideDrawer _class="w:640@lg" :loading="loading" @close="$emit('close')">
    <div class="p:24">
      <label class="--label mb:32">
        Name
        <input type="text" class="--input mt:4" v-model="form.fields.name" />
      </label>

      <label class="--label mb:32">
        Description
        <textarea class="--textarea mt:4 min-h:120" v-model="form.fields.description" />
      </label>

      <div class="flex:|center gap:16 mb:32">
        <div class="flex:1">
          <label class="--label mb:4">Type</label>
          <SelectInput v-model="form.fields.type" :options="typeOptions" />
        </div>
        <div class="flex:1">
          <label class="--label mb:4">Parent Category</label>
          <SelectInput v-model="form.fields.categoryId" :options="categoryOptions" />
        </div>
      </div>

      <label class="--label mb:4">Images</label>
      <FileUpload
        v-model="form.fields.files"
        :entity="category"
        :multiple="true"
        :extensions="['jpg', 'jpeg', 'png']"
      />
    </div>

    <template slot="footer">
      <button v-if="isEdit" @click="handleDelete" class="reset flex:|center gap:8 f:16 semi c:error py:12 r:8 o:0.6:hover">
        <Icon admin name="trash" />
        Delete
      </button>
      <button
        class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover"
        @click="handleSave"
      >
        <Icon admin name="check" />
        Save
      </button>
    </template>
  </SideDrawer>
</template>

<script>
import { mapActions } from 'vuex'

import { Form } from '@/internal/modules'

import { Category } from '@/internal'

import FileUpload from '@/views/Admin/components/FileUpload.vue'

import SideDrawer from '@/components/admin/SideDrawer.vue'

export default {
  name: 'CategoryModal',
  components: {
    SideDrawer,
    FileUpload,
  },
  data() {
    return {
      categoryIds: [],
      form: new Form({
        name: '',
        description: '',
        type: null,
        files: [],
        entityType: Category.entityTypeService,
      }),
      loading: false,
      typeOptions: Category.typeOptions,
    }
  },
  computed: {
    categories() {
      return this.categoryIds ? this.$store.getters['category/collection'](this.categoryIds) : []
    },
    categoryOptions() {
      return this.categories
        ? [
            {
              label: '-- Select Category --',
              value: null,
            },
            ...this.categories
              .filter((category) => {
                // Skip listing current entity(category) if present
                if (this.category) {
                  return category.id !== this.category.id
                } else {
                  return true
                }
              })
              .map((category) => {
                return {
                  label: category.name,
                  value: parseInt(category.id),
                }
              }),
          ]
        : []
    },
    category() {
      return this.id ? this.$store.getters['category/show'](this.id) : null
    },
    isEdit() {
      return this.$route.query.show !== 'create'
    },
    id() {
      return this.isEdit ? this.$route.query.show : null
    },
  },
  watch: {
    category() {
      if (this.category) {
        this.form.fields = {
          ...this.category,
          files: this.category.files ? this.category.files : [],
        }
      }
    },
  },
  mounted() {
    this.loadCategories()

    if (this.isEdit) {
      this.form.fields = {
        ...this.category,
        files: this.category.files ? this.category.files : [],
      }
    }
  },
  methods: {
    ...mapActions({
      categoryDestroy: 'category/destroy',
      categoryStore: 'category/store',
      categoryUpdate: 'category/update',
      categoryIndex: 'category/index',
    }),
    handleSave() {
      this.loading = true
      this.form.errors.clear()

      const fields = {
        ...this.form.fields,
      }

      let action = null

      if (this.category) {
        action = this.categoryUpdate
      } else {
        action = this.categoryStore
      }

      action(Category.preparePayload(fields))
        .then(() => {
          window.app.snackbar({
            message: 'Update successfully!',
          })
          this.$router.push('/admin/category')
        })
        .catch((error) => {
          this.form.recordErrors(error)
          window.app.snackbarError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleDelete() {
      window.app.confirm({
        title: 'Delete Category',
        message: 'Are you sure you want to delete this category?',
        onConfirm: () => {
          this.loading = true
          this.categoryDestroy({
            id: this.category.id,
          })
            .then(() => {
              window.app.snackbar('Category Deleted')

              this.$router.push('/admin/category')
            })
            .catch((e) => {
              if (e.response && e.response.data) {
                window.app.snackbar({
                  message: e.response.data.message,
                  type: 'is-danger',
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
        },
      })
    },
    loadCategories() {
      this.categoryIndex({
        params: {
          entityType: Category.entityTypeService,
        },
      })
        .then((response) => {
          this.categoryIds = response.ids
        })
        .catch((error) => {})
        .finally(() => {})
    },
  },
}
</script>
