<template>
  <div>
    <b-loading :is-full-page="true" v-model="loading"></b-loading>
    <div v-if="service">
      <div class="p:24">
        <div class="flex:between|center mb:24">
          <div class="w:380 semi c:accent-70">
            Add dining rooms, areas or spaces and correspondent tables.
          </div>
          <button
            @click="handleAddSpace"
            class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover"
          >
            <Icon admin name="plus" />
            Add Room
          </button>
        </div>
        <div v-if="spaces.length > 0">
          <div v-for="(space, index) in spaces.sort((a, b) => a.order - b.order)" class="b:base-20 r:12 mb:24">
            <div class="flex:between|center p:24|12">
              <div class="f:24 c:accent-70 semi">{{ space.title }}</div>
              <div class="flex:|center gap:16">
                <button
                  @click="handleNewItem(space)"
                  class="reset flex:|center gap:8 f:16 semi c:accent py:12 o:0.9:hover"
                >
                  <Icon admin name="plus-circle" />
                  Add new table
                </button>
                <b-dropdown
                  :ref="`spaceContextRow${space.id}`"
                  aria-role="list"
                  position="is-bottom-left"
                  class="has-text-left is-clickable"
                >
                  <template #trigger="{ active }">
                    <div @click.prevent.stop="handleSpaceRowActionClick(space)">
                      <BIcon icon="ellipsis" />
                    </div>
                  </template>

                  <b-dropdownItem aria-role="listitem" class="pr-0">
                    <div class="flex:|center gap:8" @click.prevent.stop="handleUpdateSpace(space)">
                      <Icon admin name="pencil" />
                      Update
                    </div>
                  </b-dropdownItem>

                  <b-dropdownItem aria-role="listitem" class="pr-0">
                    <div class="flex:|center gap:8" @click.prevent.stop="handleNewItemGroup(space)">
                      <Icon admin name="table" />
                      Combine Tables
                    </div>
                  </b-dropdownItem>

                  <b-dropdownItem aria-role="listitem" class="pr-0">
                    <div class="flex:|center gap:8 c:red" @click.prevent.stop="handleDeleteSpace(space)">
                      <Icon admin name="trash" />
                      Delete
                    </div>
                  </b-dropdownItem>
                </b-dropdown>
              </div>
            </div>
            <b-table
              v-if="items.filter((i) => i.serviceSpaceId == space.id).length > 0"
              :data="items.filter((i) => i.serviceSpaceId == space.id).sort((a, b) => a.priority - b.priority)"
              :striped="true"
              :hoverable="true"
              :sortable="true"
            >
              <b-table-column field="title" label="Name" v-slot="props">
                {{ props.row.title }}
              </b-table-column>

              <b-table-column field="capacity" label="Capacity" v-slot="props">
                {{ props.row.capacity }}
              </b-table-column>

              <b-table-column field="order" label="Order" v-slot="props">
                {{ props.row.priority }}
              </b-table-column>

              <b-table-column label="" v-slot="props">
                <b-dropdown
                  :ref="`contextRow${props.row.id}`"
                  aria-role="list"
                  position="is-bottom-left"
                  class="has-text-left is-clickable"
                >
                  <template #trigger="{ active }">
                    <div @click.prevent.stop="handleRowActionClick(props.row)">
                      <BIcon icon="ellipsis" />
                    </div>
                  </template>

                  <b-dropdownItem aria-role="listitem" class="pr-0">
                    <div @click.prevent.stop="handleUpdateItem(props.row)">
                      <BIcon icon="pencil-alt" />
                      Update
                    </div>
                  </b-dropdownItem>

                  <b-dropdownItem aria-role="listitem" class="pr-0">
                    <div @click.prevent.stop="handleDeleteItem(props.row)">
                      <BIcon icon="trash-alt" />
                      Delete
                    </div>
                  </b-dropdownItem>
                </b-dropdown>
              </b-table-column>
            </b-table>
            <div v-else class="flex-col:center|center p:24">
              <Icon admin name="x-tables" />
              <div class="f:16 semi c:accent-70 mt:16">No tables added</div>
            </div>
          </div>
        </div>

        <div v-else class="flex-col:center|center p:24 h:100% flex:1">
          <Icon admin name="x-tables" />
          <div class="f:16 semi c:accent-70 mt:16">No rooms and tables added</div>
        </div>
      </div>

      <ItemsDialog ref="itemsDialog" @success="handleItemAddSuccess" :items="items" />

      <SpacesDialog ref="spacesDialog" @success="handleSpaceAddSuccess" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import ItemsDialog from '@/components/admin/Service/ServiceModal/ItemsDialog.vue'
import SpacesDialog from '@/components/admin/Service/ServiceModal/SpacesDialog.vue'

export default {
  name: 'ItemsTab',
  components: {
    ItemsDialog,
    SpacesDialog,
  },
  data() {
    return {
      loading: false,
      serviceId: null,
      itemsDialog: null,
    }
  },
  computed: {
    service() {
      return this.serviceId ? this.$store.getters['service/findBy'](this.serviceId) : null
    },
    items() {
      return this.service ? this.service.items : []
    },
    spaces() {
      return this.service ? this.service.spaces : []
    },
  },
  mounted() {
    this.loadService()
  },
  methods: {
    ...mapActions({
      serviceShow: 'service/show',
      serviceItemDestroy: 'serviceItem/destroy',
      serviceSpaceDestroy: 'serviceSpace/destroy',
    }),
    handleRowActionClick(row) {
      this.$refs['contextRow' + row.id][0].toggle()
    },
    handleSpaceRowActionClick(space) {
      this.$refs['spaceContextRow' + space.id][0].toggle()
    },
    handleNewItem(space) {
      this.$refs.itemsDialog.setFormFields({
        serviceId: this.service.id,
        serviceSpaceId: space.id,
      })
      this.$refs.itemsDialog.open = true
    },
    handleNewItemGroup(space) {
      this.$refs.itemsDialog.setIsGroup(true)
      this.$refs.itemsDialog.setFormFields({
        serviceId: this.service.id,
        serviceSpaceId: space.id,
      })
      this.$refs.itemsDialog.open = true
    },
    handleUpdateItem(row) {
      this.$refs.itemsDialog.setFormFields({
        ...row,
        serviceId: this.service.id,
      })
      this.$refs.itemsDialog.open = true
    },
    handleAddSpace() {
      this.$refs.spacesDialog.setFormFields({
        serviceId: this.service.id,
      })
      this.$refs.spacesDialog.open = true
    },
    handleUpdateSpace(space) {
      this.$refs.spacesDialog.setFormFields({
        ...space,
        serviceId: this.service.id,
      })
      this.$refs.spacesDialog.open = true
    },
    handleItemAddSuccess() {
      this.loadService()
    },
    handleSpaceAddSuccess() {
      this.loadService()
    },
    handleDeleteItem(row) {
      window.app.confirm({
        title: 'Delete Table',
        message: 'Are you sure you want to delete this table?',
        onConfirm: () => {
          this.loading = true

          this.serviceItemDestroy({ id: row.id })
            .then(() => {
              window.app.snackbar('Table deleted')
              this.loadService()
            })
            .catch((e) => {
              if (e.response && e.response.data) {
                window.app.snackbar({
                  message: e.response.data.message,
                  type: 'is-danger',
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
        },
      })
    },
    handleDeleteSpace(space) {
      window.app.confirm({
        title: 'Delete Room',
        message: 'Are you sure you want to delete this room?',
        onConfirm: () => {
          this.loading = true

          this.serviceSpaceDestroy({ id: space.id })
            .then(() => {
              window.app.snackbar('Room deleted')
              this.loadService()
            })
            .catch((e) => {
              if (e.response && e.response.data) {
                window.app.snackbar({
                  message: e.response.data.message,
                  type: 'is-danger',
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
        },
      })
    },
    loadService() {
      this.loading = true

      return this.serviceShow({
        id: this.$route.query.show,
        params: {
          include: ['serviceItems', 'serviceSpaces'],
        },
      })
        .then((response) => {
          this.serviceId = response[0]
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
