<template>
  <Dialog _class="w:540@md" v-model="open" :title="form.fields.id ? 'Update room' : 'Create room'"
          @submit="handleSave" @close="handleClose">
    <div>
      <label class="--label mb:16">
        Title
        <input class="--input mt:4" type="text" v-model="form.fields.title" />
        <FormError field="title" :form="form" />
      </label>

      <label class="--label mb:16">
        Description (Optional)
        <input class="--input mt:4" type="text" v-model="form.fields.description" />
        <FormError field="description" :form="form" />
      </label>

      <label class="--label mb:16">
        Order
        <input class="--input mt:4" type="number" min="1" v-model="form.fields.priority" />
        <FormError field="priority" :form="form" />
      </label>

      <div v-if="form.fields.id" class="mb:32">
        <div class="flex:between|center">
          <label class="--label">
            Exceptions
          </label>
          <button
            @click="openUnavailabilityDialog"
            class="reset flex:|center gap:8 f:16 semi c:accent py:8 o:0.9:hover"
          >
            <Icon admin name="plus-circle" />
            Add new
          </button>
        </div>
        <table class="styled striped" style="overflow:visible !important" v-if="unavailabilities.length">
          <tr v-for="unavailability in unavailabilities">
            <td>{{ unavailability.name }}</td>
            <td>
              <b-dropdown :ref="`unavailabilityContext${unavailability.id}`"
                          aria-role="list"
                          class="is-clickable"
                          position="is-bottom-left">
                <template #trigger="{ active }">
                  <div class="px-2" @click.prevent.stop="unavailabilityContextClick(unavailability)">
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                </template>

                <b-dropdownItem aria-role="listitem" >
                  <div @click.prevent.stop="updateUnavailability(unavailability)">
                    Update
                  </div>
                </b-dropdownItem>

                <b-dropdownItem aria-role="listitem" >
                  <div @click.prevent.stop="removeUnavailability(unavailability)">
                    Remove
                  </div>
                </b-dropdownItem>
              </b-dropdown>
            </td>
          </tr>
        </table>
        <div v-else class="p:20 flex:|center gap:12 b:base-20 r:8">
          <Icon admin name="x" class="c:error" />
          No exceptions
        </div>
      </div>

      <UnavailabilityDialog ref="unavailabilityDialog" @success="handleUnavailabilitySuccess" />

    </div>
  </Dialog>
</template>

<script>

import Dialog from '@/components/admin/Dialog.vue'
import UnavailabilityDialog from '@/components/admin/UnavailabilityDialog.vue'

import { Form } from '@/internal/modules'
import { Unavailability } from '@/internal'
import { mapActions } from 'vuex'
import mixin from '@/mixin'

export default {
  name: 'SpacesDialog',
  mixins: [mixin],
  components: {
    Dialog,
    UnavailabilityDialog
  },
  computed: {
    service() {
      return this.$route.query.show ? this.$store.getters['service/show'](this.$route.query.show) : null
    },
    serviceSpace() {
      return this.form.fields.id ? this.$store.getters['serviceSpace/findBy'](this.form.fields.id) : null
    },
    unavailabilities() {
      return this.serviceSpace ? this.serviceSpace.unavailabilities : [];
    }
  },
  data() {
    return {
      loading: false,
      open: false,
      form: new Form({
        serviceId: this.$route.query.show,
        title: null,
        description: null,
        status: 'open',
        priority: 1
      }),
    }
  },
  mounted() {

  },
  methods: {
    ...mapActions({
      serviceSpaceStore: 'serviceSpace/store',
      serviceSpaceUpdate: 'serviceSpace/update',
      serviceSpaceShow: 'serviceSpace/show',
      unavailabilityDestroy: 'unavailability/destroy',
    }),
    setFormFields(fields) {
      this.form.fields = {
        ...this.form.fields,
        ...fields,
      }
    },
    loadServiceSpace() {
      this.loading = true
      return this.serviceSpaceShow({
        id: this.form.fields.id,
        params: {},
      })
        .then((response) => {
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleSave() {
      this.loading = true

      this.form.errors.clear()

      const fields = {
        ...this.form.fields,
      }

      let action = null
      if (this.form.fields.id) {
        action = this.serviceSpaceUpdate
      } else {
        action = this.serviceSpaceStore
      }

      action(fields)
        .then((response) => {
          this.clearForm()
          this.open = false
          this.$emit('success', response)
        })
        .catch((response) => {
          this.form.recordErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClose() {
      this.clearForm()
      this.$emit('close')
    },
    clearForm() {
      this.form.fields = {
        serviceId: this.$route.query.show,
        title: null,
        description: null,
        status: 'open',
        priority: 1
      }
    },
    openUnavailabilityDialog() {
      this.$refs.unavailabilityDialog.setFormFields({
        entityId: this.form.fields.id,
        entityType: Unavailability.entityTypeServiceSpace
      });
      this.$refs.unavailabilityDialog.open = true;
    },
    handleUnavailabilitySuccess() {
      this.loadServiceSpace();
    },
    unavailabilityContextClick(unavailability) {
      this.$refs['unavailabilityContext' + unavailability.id][0].toggle();
    },
    updateUnavailability(unavailability) {
      this.$refs.unavailabilityDialog.setFormFields(unavailability);
      this.$refs.unavailabilityDialog.open = true;
    },
    removeUnavailability(unavailability) {
      window.app.confirm({
        title: 'Remove Exception',
        message: 'Are you sure you want to remove this exception?',
        onConfirm: () => {
          this.loading = true
          this.unavailabilityDestroy({
            id: unavailability.id,
          }).then(() => {
            this.loadServiceSpace();
            window.app.snackbar('Exception removed')
          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              })
            }
          }).finally(() => {
            this.loading = false
          })
        },
      })
    },
  },
}
</script>
