<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div class="p:24">
      <div class="font--primary f:36 bold mb:24">My Account</div>
      <div class="max-w:400 flex-col gap:12">
        <label class="--label">
          Name *
          <input type="text" class="--input mt:4" v-model="form.fields.username" />
        </label>

        <label class="--label">
          Email *
          <input type="text" class="--input mt:4" v-model="form.fields.email" disabled />
        </label>

        <button @click="handleChangePasswordClick" class="reset flex-inline f:16 c:accent mt:16">
          Change password
        </button>

        <template v-if="form.fields.changePassword">
          <label class="--label">
            Old password *
            <input type="password" class="--input mt:4" v-model="form.fields.password" />
          </label>

          <label class="--label">
            New password *
            <input type="password" class="--input mt:4" v-model="form.fields.newPassword" />
          </label>

          <label class="--label">
            New password confirmation *
            <input type="password" class="--input mt:4" v-model="form.fields.newPasswordConfirm" />
          </label>
        </template>
        <div class="mt:16 flex:end">
          <button
            class="reset flex-inline:|center gap:8 ff:callout f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover"
            @click="handleSave"
          >
            <Icon admin name="check" />
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Form } from '@/internal/modules'

export default {
  name: 'Account',
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
  },
  watch: {
    user() {
      this.fillForm()
    },
  },
  mounted() {
    if (this.user) {
      this.fillForm()
    }
  },
  data() {
    return {
      form: new Form({
        username: '',
        email: '',
        changePassword: false,
        password: null,
        newPassword: null,
        newPasswordConfirm: null,
      }),
      loading: false,
    }
  },
  methods: {
    ...mapActions({
      serviceIndex: 'service/index',
      userDestroy: 'user/destroy',
      userStore: 'user/store',
      userUpdate: 'user/update',
      userCalendar: 'user/calendar',
    }),
    handleSave() {
      this.loading = true
      this.form.errors.clear()

      let action = null

      if (this.user) {
        action = this.userUpdate
      } else {
        action = this.userStore
      }

      action({
        ...this.form.fields,
      })
        .then(() => {
          window.app.snackbar({
            message: 'Saved successfully!',
          })
          this.$router.push('/admin/user')
        })
        .catch((error) => {
          this.form.recordErrors(error)
          window.app.snackbarError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleChangePasswordClick() {
      this.form.fields.changePassword = !this.form.fields.changePassword
    },
    fillForm() {
      if (!this.user) {
        return
      }

      this.form.fields = {
        ...this.form.fields,
        id: this.user.id,
        email: this.user.email,
        username: this.user.username,
      }
    },
  },
}
</script>
