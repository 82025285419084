import {
    Model
} from '@/internal/modules';

import {
  Unavailability,
  ServiceSpaceService as ServiceSpaceService,
} from '@/internal';


export default class ServiceSpace extends Model {

    static get name() {
        return 'ServiceSpace';
    }

    static get vuexModuleKey() {
        return 'serviceSpace';
    }

    static get service() {
        return ServiceSpaceService;
    }

    static get fields () {
        return {};
    }

    get unavailabilities() {
      return this.hasMany(Unavailability);
    }
}
