import Vue from 'vue';
import Vuex from 'vuex';
import { StoreBuilder } from '@/internal/modules';

import AuthStore from '@/domain/Auth/AuthStore';
import AccountStore from '@/domain/Account/AccountStore';
import AccountIntegrationStore from '@/domain/AccountIntegration/AccountIntegrationStore';
import ActiveBookingStore from '@/domain/ActiveBooking/ActiveBookingStore';
import ActivityLogStore from '@/domain/ActivityLog/ActivityLogStore';
import AnnouncementStore from '@/domain/Announcement/AnnouncementStore';
import CategoryStore from '@/domain/Category/CategoryStore';
import CustomerStore from '@/domain/Customer/CustomerStore';
import GroupStore from '@/domain/Group/GroupStore';
import CartStore from '@/domain/Cart/CartStore';
import CartServiceStore from '@/domain/CartService/CartServiceStore';
import CountryStore from '@/domain/Country/CountryStore';
import ClosedHourStore from '@/domain/ClosedHour/ClosedHourStore';
import DashboardStore from '@/domain/Dashboard/DashboardStore';
import FileStore from '@/domain/File/FileStore';
import BookingGroupStore from '@/domain/BookingGroup/BookingGroupStore';
import IntegrationStore from '@/domain/IntegrationLog/IntegrationLogStore';
import InformationStore from '@/domain/Information/InformationStore';
import UserStore from '@/domain/User/UserStore';
import BookingStore from '@/domain/Booking/BookingStore';
import NonScheduledBookingStore from '@/domain/NonScheduledBooking/NonScheduledBookingStore';
import OrderStore from '@/domain/Order/OrderStore';
import OrderProductStore from '@/domain/OrderProduct/OrderProductStore';
import PaymentStore from '@/domain/Payment/PaymentStore';
import UnavailabilityStore from '@/domain/Unavailability/UnavailabilityStore';
import ServiceStore from '@/domain/Service/ServiceStore';
import RelatedServiceStore from '@/domain/RelatedService/RelatedServiceStore';
import ProductStore from '@/domain/Product/ProductStore';
import ServiceWorkingHourStore from '@/domain/ServiceWorkingHour/ServiceWorkingHourStore';
import ServiceClosedHourStore from '@/domain/ServiceClosedHour/ServiceClosedHourStore';
import ServiceProductStore from '@/domain/ServiceProduct/ServiceProductStore';
import ServiceRuleStore from '@/domain/ServiceRule/ServiceRuleStore';
import ServiceItemStore from '@/domain/ServiceItem/ServiceItemStore';
import ServiceSpaceStore from '@/domain/ServiceSpace/ServiceSpaceStore';
import SystemStore from '@/domain/System/SystemStore';
import NotificationStore from '@/domain/Notification/NotificationStore';

export const storeBuilder = new StoreBuilder();

Vue.use(Vuex);

storeBuilder.addModule(AuthStore);
storeBuilder.addModule(AccountStore);
storeBuilder.addModule(AccountIntegrationStore);
storeBuilder.addModule(ActiveBookingStore);
storeBuilder.addModule(ActivityLogStore);
storeBuilder.addModule(AnnouncementStore);
storeBuilder.addModule(CategoryStore);
storeBuilder.addModule(CustomerStore);
storeBuilder.addModule(GroupStore);
storeBuilder.addModule(CartStore);
storeBuilder.addModule(CartServiceStore);
storeBuilder.addModule(CountryStore);
storeBuilder.addModule(DashboardStore);
storeBuilder.addModule(ClosedHourStore);
storeBuilder.addModule(FileStore);
storeBuilder.addModule(BookingGroupStore);
storeBuilder.addModule(IntegrationStore);
storeBuilder.addModule(InformationStore);
storeBuilder.addModule(UserStore);
storeBuilder.addModule(BookingStore);
storeBuilder.addModule(NonScheduledBookingStore);
storeBuilder.addModule(OrderStore);
storeBuilder.addModule(OrderProductStore);
storeBuilder.addModule(PaymentStore);
storeBuilder.addModule(UnavailabilityStore);
storeBuilder.addModule(ServiceStore);
storeBuilder.addModule(RelatedServiceStore);
storeBuilder.addModule(ProductStore);
storeBuilder.addModule(ServiceWorkingHourStore);
storeBuilder.addModule(ServiceClosedHourStore);
storeBuilder.addModule(ServiceProductStore);
storeBuilder.addModule(ServiceRuleStore);
storeBuilder.addModule(ServiceItemStore);
storeBuilder.addModule(ServiceSpaceStore);
storeBuilder.addModule(SystemStore);
storeBuilder.addModule(NotificationStore);

export default new Vuex.Store({
    modules: storeBuilder.getVuexModules(),
});
