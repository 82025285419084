<template>
  <div class="rel">
    <b-loading v-model="loading" :is-full-page="false" />
    <table class="styled striped f:14">
      <tr>
        <th>Entity</th>
        <th>Service</th>
        <th>Action</th>
        <th>User</th>
        <th>Date</th>
        <th class="ta:right">Payment</th>
      </tr>

      <tr v-for="activityLog in activityLogs">
        <td>
          <template v-if="activityLog.entityName === 'Booking'">
            <router-link :to="`/admin/booking?edit=${activityLog.entityId}`">
              Booking #{{ activityLog.bookingNumber }}
            </router-link>
          </template>
          <template v-else>
            {{ activityLog.entityName }}
            <span v-if="activityLog.entityId">#{{ activityLog.entityId }}</span>
          </template>
        </td>
        <td>
          {{ activityLog.data ? activityLog.data.serviceName : '' }}
        </td>
        <td>
          {{ activityLog.action }}
        </td>
        <td>{{ activityLog.user.name }} ({{ activityLog.user.email }})</td>
        <td class="nowrap">
          {{ activityLog.createdAt.format('ddd, D MMM YYYY') }}
          <br />
          {{ activityLog.createdAt.format('hh:mm A') }}
        </td>
        <td class="ta:right">
          {{ activityLog.data ? activityLog.data.paymentStatus : '' }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ActivityTab',
  data() {
    return {
      activityLogIds: [],
      loading: false,
    }
  },
  computed: {
    activityLogs() {
      return this.activityLogIds ? this.$store.getters['activityLog/collection'](this.activityLogIds) : []
    },
  },
  mounted() {
    this.loadActivityLogs()
  },
  methods: {
    ...mapActions({
      activityLogIndex: 'activityLog/index',
    }),
    loadActivityLogs() {
      this.loading = true
      this.activityLogIndex({
        params: {
          customer_id: this.$route.query.show,
          include: ['user'],
        },
      }).then((response) => {
        this.activityLogIds = response.ids
        this.loading = false
      })
    },
  },
}
</script>
