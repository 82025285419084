import {
    Model,
} from '@/internal/modules';

import {
    Customer,
    OrderProduct,
    OrderService as OrderService
} from '@/internal';

export default class Order extends Model {
    static get name() {
        return 'Order';
    }
    static get vuexModuleKey() {
        return 'order';
    }
    static get service() {
        return OrderService;
    }

    static get fields () {
        return {
            status: {
                required: true,
                type: String,
            },
            createdAt: {
                type: Date
            },
            deliveryAt: {
              type: Date
            }
        };
    }

    get customer() {
        return this.hasOne(Customer)
    }

    get orderProducts() {
        return this.hasMany(OrderProduct);
    }

    static get originPlatformGuestApp() {
        return 'guest-app';
    };

    static get originPlatformBackoffice() {
        return 'back-office';
    };

    static get statusOptions() {
        return [
            {
                label: 'Requested',
                value: 'requested',
                color: 'success',
                editable: true
            },
            {
                label: 'Confirmed',
                value: 'confirmed',
                color: 'success',
                editable: true
            },
            {
                label: 'Canceled',
                value: 'canceled',
                color: 'error',
                editable: true
            },
        ]
    }

    static get checkoutTypeAddToBill() {
        return 'add-to-bill';
    };
}
