import {
    Model
} from '@/internal/modules';

import {
  UnavailabilityService as UnavailabilityService
} from '@/internal';


export default class Unavailability extends Model {

    static get name() {
        return 'Unavailability';
    }

    static get vuexModuleKey() {
        return 'unavailability';
    }

    static get service() {
        return UnavailabilityService;
    }

    static get fields () {
        return {};
    }

    static get entityTypeServiceSpace() {
      return "App\\Models\\ServiceSpace";
    }

    static get weekDaysOptions() {
        return [
            {
                label: 'Mondays',
                value: 1,
            },
            {
                label: 'Tuesdays',
                value: 2,
            },
            {
                label: 'Wednesdays',
                value: 3,
            },
            {
                label: 'Thursdays',
                value: 4,
            },
            {
                label: 'Fridays',
                value: 5,
            },
            {
                label: 'Saturdays',
                value: 6,
            },
            {
                label: 'Sundays',
                value: 7,
            },
        ]
    }

}
