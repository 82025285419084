<template>
  <Dialog
    _class="w:540@md"
    v-model="open"
    title="Payment method"
    submit-text="Pay"
    @submit="handlePay"
    @close="handleClose"
    :disabled-submit="disabled"
  >
    <b-loading :is-full-page="true" v-model="loading" />
    <div class="min-h:164">
      <div class="mb:32">
        <label class="--label mb:4">{{ title }}</label>
        <div class="has-text-weight-semibold">{{ description }}</div>
        <div class="has-text-weight-semibold">£ {{ amount }}</div>
      </div>

      <div class="mb:16">
        <label class="--label mb:4">Payment method</label>
        <div class="flex:|center gap:16">
          <label
            v-for="paymentTypeOption in paymentTypeOptions"
            :key="paymentTypeOption.value"
            class="--label flex-inline:|center gap:4 f:16"
          >
            <input
              type="radio"
              class="--radio"
              v-model="form.fields.payment_type"
              @input="handlePaymentTypeChange"
              name="name"
              :value="paymentTypeOption.value"
            />
            {{ paymentTypeOption.label }}
          </label>
        </div>
      </div>

      <label class="--label mb:16" v-if="['stripe_voucher'].includes(form.fields.payment_type)">
        Stripe amount
        <input class="--input mt:4" type="text" v-model="form.fields.stripe_amount" />
        <FormError field="stripe_amount" :form="form" />
      </label>

      <label class="--label mb:16" v-if="['stripe_voucher'].includes(form.fields.payment_type)">
        Voucher amount
        <input class="--input mt:4" type="text" v-model="form.fields.voucher_amount" />
        <FormError field="voucher_amount" :form="form" />
      </label>

      <label class="--label mb:16" v-if="['voucher', 'stripe_voucher'].includes(form.fields.payment_type)">
        Voucher code
        <input class="--input mt:4" type="text" v-model="form.fields.voucher_code" />
        <FormError field="voucher_code" :form="form" />
      </label>
    </div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/admin/Dialog.vue'

import { Form } from '@/internal/modules'
import { Booking } from '@/internal'
import { mapActions } from 'vuex'

export default {
  name: 'BookingPaymentDialog',
  components: {
    Dialog,
  },
  computed: {
    title() {
      return this.service ? this.service.name : null
    },
    description() {
      return this.booking
        ? `${this.booking.group}, ${this.booking.date} at ${this.booking.time} for ${this.booking.duration}`
        : null
    },
    amount() {
      return this.booking ? this.booking.price : 0
    },
    disabled() {
      if (this.form.fields.payment_type === 'voucher') {
        return !this.form.fields.voucher_code
      }

      if (this.form.fields.payment_type === 'stripe_voucher') {
        return (
          parseInt(this.form.fields.stripe_amount || 0) + parseInt(this.form.fields.voucher_amount || 0) != this.amount
        )
      }

      return false
    },
  },
  data() {
    return {
      open: false,
      service: null,
      booking: null,
      form: new Form({
        voucher_code: null,
        voucher_amount: null,
        stripe_amount: null,
        payment_type: 'stripe',
      }),
      paymentTypeOptions: Booking.paymentTypeOptions,
      loading: false,
    }
  },
  methods: {
    ...mapActions({
      bookingStore: 'booking/store',
      createPaymentSession: 'payment/session',
    }),
    handlePaymentTypeChange() {
      console.log(this.form.fields.payment_type)
    },
    handlePay() {
      if (this.form.fields.payment_type === 'stripe' || this.form.fields.payment_type === 'stripe_voucher') {
        this.handlePayWithStripe()
      } else if (this.form.fields.payment_type === 'voucher') {
        this.handleBookWithVoucher()
      }
    },
    handlePayWithStripe() {
      this.loading = true
      this.form.errors.clear()

      const payload = {
        product_name: this.title,
        product_description: this.description,
        amount:
          this.form.fields.payment_type === 'stripe_voucher'
            ? this.form.fields.stripe_amount * 100
            : this.booking.price * 100,
        cancel_url: process.env.APP_URL + this.$route.fullPath,
        success_url: process.env.APP_URL + this.$route.fullPath,
        origin_platform: this.form.fields.origin_platform,
        payment_type: this.form.fields.payment_type,
        voucher_code: this.form.fields.voucher_code,
        voucher_amount: this.form.fields.voucher_amount,
        stripe_amount: this.form.fields.stripe_amount,
        metadata: {
          ...this.form.fields,
          service_id: this.service.id,
          service_type: this.service.type,
          price: this.booking.price,
        },
      }

      this.createPaymentSession(payload)
        .then((response) => {
          window.location.href = response.url
        })
        .catch((error) => {
          this.form.recordErrors(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleBookWithVoucher() {
      this.loading = true
      this.form.errors.clear()

      const fields = {
        ...this.form.fields,
        price: this.booking.price,
      }

      this.bookingStore(fields)
        .then((response) => {
          this.clearData()
          this.$emit('success')
        })
        .catch((response) => {
          this.form.recordErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClose() {
      this.clearData()
    },
    setPaymentData(data) {
      this.service = data.service
      this.booking = data.booking
      this.form.fields = {
        ...this.form.fields,
        ...data.form.fields,
      }
    },
    clearData() {
      this.form.fields = {
        voucher_code: null,
        voucher_amount: null,
        stripe_amount: null,
        payment_type: 'stripe',
      }
      this.service = null
      this.booking = null
    },
  },
}
</script>
