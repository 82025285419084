import {
   Unavailability as Model
} from '@/internal';

import {
    ResourceStore,
} from '@/internal/modules';


const store = new ResourceStore({
    key: 'unavailability',
    model: Model,
});

export default store.toObject();
