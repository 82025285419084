<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" />

        <div class="p:12 flex:between|center">
          <div>
            <div class="font--primary f:36 bold">
              {{ paginator ? paginator.total : 0 }} Bookings
            </div>
            <div class="f:14 c:base-50">
              {{ meta.total_guests ? meta.total_guests : 0 }} guests
            </div>
          </div>

          <button
            class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent bg:base-40:disabled p:10|12 r:8 o:0.9:hover"
            @click="handleNewBookingClick"
            :disabled="bookingButtonDisabled"
          >
            <Icon admin name="book-plus" />
            New Booking
          </button>
        </div>

        <section class="section p-3">
            <div class="is-flex">
                <div>
                    <SelectInput
                        v-model="params.serviceId"
                        :options="serviceOptions"
                        placeholder="Any service"
                        @input="handleServiceSelect" />
                </div>

                <div class="ml-2">
                    <CalendarInput v-model="params.startDate"
                                   @input="handleStartDateChange"
                                   @clear="handleClearDate"
                                   :allow-clear="false"
                    />
                </div>

                <div class="ml-2">
                  <ComboInput
                    v-model="selectedStatuses"
                    @input="handleStatusChange"
                    :options="statusOptions"
                  />
                </div>

                <div class="ml-auto">
                    <div class="new-input">
                        <b-input placeholder="Search..."
                                 ref="search"
                                 type="search"
                                 icon="magnifying-glass"
                                 v-model="search"
                                 @input="handleSearch"
                        >
                        </b-input>
                        <div v-show="!search || search === ''" class="new-input-icon new-input-icon--right new-input--search">
                            ⌘F
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section p-3" style="height: calc(100vh - 202px);">
            <div class="card p-4 border--light-grey" style="height: 100%">
                <div class="card-content p-0 is-flex is-flex-direction-column is-justify-content-space-between" style="height: 100%;">
                    <template v-if="data.length">
                        <div class="columns" style="height: calc(100% - 52px); overflow-y: auto;">
                            <div class="column">
                                <b-table
                                    sticky-header
                                    height="calc(100vh - 324px)"
                                    :data="data"
                                    :striped="true"
                                    :hoverable="true"
                                    :sortable="true"
                                    @click="handleBookingUpdate"
                                    @sort="handleChangeSort">

                                    <b-table-column v-if="!this.params.startDate"
                                                    label="Date"
                                                    cell-class="rounded-8--left"
                                                    v-slot="props"
                                    >
                                        {{ dateFormat(props.row.startAt) }}
                                    </b-table-column>

                                    <b-table-column field="startAt" label="Time" width="180" :cell-class="params.startDate ? 'rounded-8--left' : ''" v-slot="props">
                                        {{ timeFormat(props.row.startAt) }} - {{ timeFormat(props.row.endAt) }}
                                    </b-table-column>

                                    <b-table-column v-if="!this.params.serviceId" field="service.name" label="Service" v-slot="props">
                                        {{ props.row.service ? props.row.service.name : "" }}
                                    </b-table-column>

                                    <b-table-column field="customer" label="Customer" v-slot="props">
                                        {{ props.row.customer ? props.row.customer.name : "" }}
                                    </b-table-column>

                                    <b-table-column field="adults_number" label="Persons" v-slot="props">
                                        <template v-if="props.row.adultsNumber">
                                            <div class="is-flex align-items-center">
                                                <img src="/images/users2-1.svg" alt="">
                                                <div>{{ props.row.adultsNumber }}</div>
                                            </div>
                                        </template>
                                        <template v-if="props.row.childrenNumber">
                                            <BIcon icon="child"/> {{ props.row.childrenNumber }}
                                        </template>
                                    </b-table-column>

                                    <template>

                                    </template>

                                    <b-table-column v-if="serviceToBook && serviceToBook.subtype === 'restaurant'" field="service_item_id" label="Table" v-slot="props">
                                        {{ props.row.serviceItemTitle }}
                                    </b-table-column>

                                    <template v-if="serviceToBook && serviceToBook.activityType === 'spa'">
                                        <b-table-column field="shoeSizes" label="Shoe sizes" v-slot="props">
                                            {{ props.row.shoeSizes }}
                                        </b-table-column>

                                        <b-table-column field="formStatus" label="Form" v-slot="props">
                                            <b-dropdown :ref="`formStatusRow${props.row.id}`" aria-role="list" position="is-bottom-left"
                                                        class="has-text-left is-clickable">

                                                <template #trigger="{ active }">

                                                    <div @click.prevent.stop="handleFormStatusActionClick(props.row)" >
                                                        <b-button outlined
                                                                  :label="props.row.formStatusName"
                                                                  type="is-dark"
                                                                  :icon-right="active ? 'chevron-up' : 'chevron-down'" />
                                                    </div>
                                                </template>

                                                <template v-for="formStatusOption in formStatusOptions">
                                                    <b-dropdownItem aria-role="listitem" :disabled="formStatusOption.value === props.row.package" >
                                                        <div @click.prevent.stop="handleFormStatusUpdate(props.row, formStatusOption)">
                                                            {{ formStatusOption.label }}
                                                        </div>
                                                    </b-dropdownItem>
                                                </template>

                                            </b-dropdown>
                                        </b-table-column>
                                    </template>
                                    <template v-if="serviceToBook && serviceToBook.activityType === 'restaurant'">
                                        <b-table-column field="Special occasion" label="Special occasion" v-slot="props">
                                            {{ props.row.specialOccasion }}
                                        </b-table-column>

                                        <b-table-column field="dietaryRequirements" label="Dietary requirements" v-slot="props">
                                            {{ props.row.dietaryRequirements }}
                                        </b-table-column>

                                        <b-table-column field="package" label="Package" v-slot="props">
                                            <b-dropdown :ref="`packageRow${props.row.id}`" aria-role="list" position="is-bottom-left"
                                                        class="has-text-left is-clickable">

                                                <template #trigger="{ active }">

                                                    <div @click.prevent.stop="handlePackageActionClick(props.row)" >
                                                        <b-button outlined
                                                                  :label="props.row.packageName"
                                                                  type="is-dark"
                                                                  :icon-right="active ? 'chevron-up' : 'chevron-down'" />
                                                    </div>
                                                </template>

                                                <template v-for="packageOption in packageOptions">
                                                    <b-dropdownItem aria-role="listitem" :disabled="packageOption.value === props.row.package" >
                                                        <div @click.prevent.stop="handlePackageUpdate(props.row, packageOption)">
                                                            {{ packageOption.label }}
                                                        </div>
                                                    </b-dropdownItem>
                                                </template>

                                            </b-dropdown>
                                        </b-table-column>
                                    </template>

                                    <b-table-column field="note" label="Notes" v-slot="props">
                                        {{ props.row.note }}
                                    </b-table-column>

                                    <b-table-column field="status" label="Status" v-slot="props">

                                        <BookingStatusUpdate :ref="`statusRow${props.row.id}`"
                                                             :options="editableStatusOptions"
                                                             :id="props.row.id"
                                                             :status="props.row.status"
                                                             @update="handleStatusUpdate(props.row, $event)"
                                        />

                                    </b-table-column>

                                    <b-table-column @click.stop
                                                    field="action"
                                                    label=""
                                                    cell-class="rounded-8--right"
                                                    v-slot="props"
                                        >
                                        <div class="is-flex is-justify-content-end">
                                            <div style="min-width: 20px">
                                                <i v-if="props.row.customer && props.row.customer.isResident" class="fa-solid fa-house-chimney-user" title="Resident"></i>
                                                <i v-if="props.row.checkoutType === 'paid'" class="fa-solid fa-circle-dollar-to-slot" title="Paid"></i>
                                                <i v-if="props.row.checkoutType === 'add-to-bill'" class="fa-solid fa-file-invoice-dollar" title="Added to bill"></i>
                                            </div>
                                            <b-dropdown :ref="`contextRow${props.row.id}`"
                                                        aria-role="list"
                                                        class="is-clickable"
                                                        position="is-bottom-left">
                                                <template #trigger="{ active }">
                                                    <div class="px-2" @click.prevent.stop="handleContextActionClick(props.row)">
                                                        <i class="fa-solid fa-ellipsis-vertical"></i>
                                                    </div>
                                                </template>

                                                <b-dropdownItem aria-role="listitem" >
                                                    <div @click.prevent.stop="handleBookingUpdate(props.row)">
                                                        View
                                                    </div>
                                                </b-dropdownItem>
                                                <b-dropdownItem class="pr:0!" aria-role="listitem" :disabled="rescheduleDisabled(props.row)">
                                                    <div @click.prevent.stop="handleRescheduleBooking(props.row)">
                                                      Update
                                                    </div>
                                                </b-dropdownItem>
                                            </b-dropdown>
                                        </div>
                                    </b-table-column>

                                </b-table>
                            </div>
                        </div>
                        <div v-if="paginator" class="columns">
                            <div class="column">
                                <Paginator :paginator="paginator"></Paginator>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <section class="hero">
                            <div class="hero-body">
                                <p class="has-text-centered">
                                    No bookings found for selected date
                                </p>
                            </div>
                        </section>
                    </template>
                </div>
            </div>

        </section>

        <template v-if="dataLoaded">
          <BookingUpdateModal
            v-if="$route.query.show"
            :id="$route.query.show"
            @close="$router.back()"
          />

          <Transition name="fade-slide">
            <BookingCreateModal v-if="bookingCreateModalActive"
                                :service="serviceToBook"
                                :date="params.startDate"
                                @success="handleBookingSuccess"
                                @close="closeBookingCreateModal" />
          </Transition>

          <BookingRescheduleModal
            v-if="$route.query.edit"
            :id="$route.query.edit"
            @close="$router.back()"
            @success="handleBookingRescheduleSuccess"
          />
        </template>

    </div>
</template>

<script>

import {mapActions} from 'vuex';
import Paginator from '@/views/Admin/components/Paginator';
import BookingModal from '@/views/Admin/components/BookingModal';
import BookingCreateModal from '@/components/admin/BookingCreateModal';
import BookingUpdateModal from '@/components/admin/Booking/BookingUpdateModal.vue';
import BookingRescheduleModal from '@/components/admin/Booking/BookingRescheduleModal.vue';
import BookingStatusUpdate from '@/views/Admin/components/BookingStatusUpdate';
import CalendarInput from "@/components/admin/CalendarInput";
import ComboInput from '@/views/Admin/components/ComboInput';
import {debounce} from "lodash";
import moment from "moment-timezone";
import mixin from "@/mixin";
import _ from 'lodash';
import Vue from 'vue';

import {
    Booking,
} from '@/internal'

export default {
    name: 'Index',
    mixins: [mixin],
    components: {
        CalendarInput,
        BookingStatusUpdate,
        ComboInput,
        Paginator,
        BookingModal,
        BookingRescheduleModal,
        BookingUpdateModal,
        BookingCreateModal
    },
    computed: {
      timezone() {
        return this.$store.state.system.timezone;
      },
      data() {
        return this.ids ? this.$store.getters['booking/collection'](this.ids) : [];
      },
      services() {
        return this.serviceIds ? this.$store.getters['service/collection'](this.serviceIds) : [];
      },
      serviceOptions() {
        return [
            {
                value: null,
                label: "Any service"
            },
            ...this.services.map((service) => {
                return {
                    value: service.id,
                    label: service.name
                }
            })
        ];
      },
      fullPath () {
        return this.$route.fullPath;
      },
      bookingButtonDisabled() {
          return !this.params.serviceId || !this.params.startDate;
      },
      serviceToBook() {
          return this.services ? this.services.find(service => service.id === this.params.serviceId) : null;
      },
      selectedStatusString() {
          if(this.params.status.length > 2) {
            const arr = this.params.status;
            return arr.slice(0,2).join(",") + "...";
          } else if(this.params.status.length > 0 && this.params.status.length <= 2) {
            return this.params.status.join(",");
          } else {
            return "Any status";
          }
      },
      editableStatusOptions() {
          return this.statusOptions.filter(status => status.editable);
      },
      selectedService() {
          const service = this.serviceOptions.find(option => option.value === this.params.serviceId)
          return service ? service.label : this.serviceOptions[0].label
      },
      selectedStatus() {
          const status = this.statusOptions.find(option => option.value === this.params.serviceId)
          return status ? status.label : this.statusOptions[0].label
      }
    },
    created() {
        window.addEventListener('keydown', this.focusSearch);

        this.loadServices();

        this.loadData(true);

        this.params.status.forEach(s => {
            this.selectedStatuses.push(this.statusOptions.find(status => status.value === s))
        })
    },
    data() {
        return {
            ids: [],
            serviceIds: [],
            paginator: null,
            meta: {},
            search: this.$route.query.search || null,
            performSearch: debounce(() => {
                if(this.search && this.search !== "") {
                    this.updateQuery('search', this.search);
                } else {
                    this.updateQuery('search', null);
                }
            }, 1000),
            types: Booking.typeOptions,
            statusOptions: Booking.statusOptions,
            packageOptions: Booking.packageOptions,
            formStatusOptions: Booking.formStatusOptions,
            type: this.$route.query.type || Booking.typeOptions[0].value,
            loading: false,
            params: {
                serviceId: this.$route.query.serviceId || null,
                startDate: this.$route.query.startDate ? moment(this.$route.query.startDate).toDate() : new Date(),
                search: this.$route.query.search || null,
                status: this.$route.query.status ? this.$route.query.status.split(",") : ['optional','confirmed','started','processed']
            },
            bookingRescheduleModalActive: false,
            bookingToReschedule: null,
            bookingUpdateModalActive: false,
            bookingCreateModalActive: false,
            bookingToUpdateId: null,
            selectedStatuses: [],
            selectedRowId: null,
            dataLoaded: false
        }
    },
    methods: {
        ...mapActions({
            bookingIndex: 'booking/index',
            bookingDestroy: 'booking/destroy',
            bookingUpdate: 'booking/update',
            bookingStatusUpdate: 'booking/statusUpdate',
            serviceIndex: 'service/index',
            bookingShow: 'booking/show',
        }),
        closeBookingUpdateModal() {
            this.bookingUpdateModalActive = false;
            this.selectedRowId = null;
        },
        closeBookingCreateModal() {
            this.bookingCreateModalActive = false;
        },
        formatDatepickerDate(date) {
            return date ? date.toLocaleDateString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"}) : ''
        },
        dateFormat(value) {
            return this.dateFormatTimezone(value).format('ddd, D MMM YYYY');
        },
        timeFormat(value) {
            return this.dateFormatTimezone(value).format('hh:mm A');
        },
        handleClearDate() {
            this.updateQuery('startDate', null);
            this.params.startDate = null;
        },
        handleBookingUpdate(row) {
          this.updateQuery('show', row.id);
        },
        handleChangeSort (sortBy, sortOrder, event) {
            if(!event) {
                return
            }

            event.preventDefault();

            const route = Object.assign({}, this.$route);

            this.$router.push({
                name: route.name,
                query: {
                    ...route.query,
                    sortBy: sortBy,
                    sortOrder: sortOrder,
                },
            });
        },
        handleSearch() {
            this.performSearch();
        },
        handleTypeSelect() {
            this.updateQuery('type', this.type || Booking.typeOptions[0].value)
        },
        handleServiceSelect() {
            this.updateQuery('serviceId', this.params.serviceId)
        },
        handleStartDateChange(startDate) {
            this.params.startDate = startDate;
            this.updateQuery('startDate', startDate ? moment(startDate).format("YYYY-MM-DD") : null)
        },
        handleStatusChange(status) {
            this.params.status = status.map(s => s.value)
            this.updateQuery('status', status.length ? status.map(s => s.value).join(",") : null)
        },
        handleNewBookingClick() {
            this.bookingCreateModalActive = true;
        },
        handleBookingSuccess() {
            this.bookingCreateModalActive = false;
            this.loadData();
        },
        handleBookingRescheduleSuccess() {
            this.$router.back();
            Vue.nextTick(()=> {
                this.loadData();
            });
        },
        updateQuery(key, value) {
            const route = Object.assign({}, this.$route);

            const query = {
                ...route.query,
            };

            if(value) {
                query[key] = value;
            } else {
                delete query[key];
            }


            this.$router.push({
                name: route.name,
                query
            });
        },
        // handleStatusActionClick(id) {
        //     // console.log('handleStatusActionClick', row.id)
        //     this.$refs['statusRow' + id].toggle();
        // },
        handleContextActionClick(row) {
            this.$refs['contextRow' + row.id].toggle();
        },
        handlePackageActionClick(row) {
            this.$refs['packageRow' + row.id].toggle();
        },
        handleFormStatusActionClick(row) {
            this.$refs['formStatusRow' + row.id].toggle();
        },
        handleRowActionUpdate(row) {
            const route = window.app.findRouteByName('admin/booking/update', [], {
                params: {
                    bookingId: row.id
                },
            });

            this.$router.push(route);
        },
        handlePackageUpdate(row, packageOption) {
            if(row.package === packageOption.value) {
                return;
            }

            window.app.confirm({
                title: 'Change package',
                message: `Are you sure you want to change booking package to ${packageOption.label}?`,
                type: 'is-info',
                onConfirm: () => {
                    this.loading = true;

                    this.bookingUpdate({
                        id: row.id,
                        package: packageOption.value
                    }).then(() => {
                        window.app.snackbar('Booking package updates');
                        this.loadData();
                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });

                },
            });
        },
        handleFormStatusUpdate(row, formStatusOption) {
            if(row.package === formStatusOption.value) {
                return;
            }

            window.app.confirm({
                title: 'Change form status',
                message: `Are you sure you want to change form status to ${formStatusOption.label}?`,
                type: 'is-info',
                onConfirm: () => {
                    this.loading = true;

                    this.bookingUpdate({
                        id: row.id,
                        form_status: formStatusOption.value
                    }).then(() => {
                        window.app.snackbar('Form status updates');
                        this.loadData();
                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });

                },
            });
        },
        handleStatusUpdate(row, statusOption) {
            if(row.status === statusOption.value) {
                return;
            }

            window.app.confirm({
                title: 'Change status',
                message: `Are you sure you want to change booking status to ${statusOption.label}?`,
                type: 'is-info',
                onConfirm: () => {
                    this.loading = true;

                    this.bookingStatusUpdate({
                        id: row.id,
                        status: statusOption.value
                    }).then(() => {
                        window.app.snackbar('Booking status updates');
                        this.loadData();
                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });

                },
            });
        },
        handleRowActionDelete(row) {
            window.app.confirm({
                title: 'Delete Booking',
                message: 'Are you sure you want to delete this booking?',
                onConfirm: () => {
                    this.loading = true;

                    this.bookingDestroy({id: row.id}).then(() => {
                        window.app.snackbar('Booking deleted');
                        this.loadData();
                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });

                },
            });

        },
        handleRescheduleBooking(row) {
          this.updateQuery('edit', row.id);
        },
        loadServices() {
            this.loading = true;

            this.serviceIndex({}).then((response) => {
                this.serviceIds = response.ids;
            }).finally(() => {
                this.loading = false;
                this.dataLoaded = true;
            })
        },
        loadData(initial) {
            // If not initial load and BookingUpdateModal is active
           if(!initial && (this.$route.query.show || this.$route.query.edit) ) {
             return;
            }

            this.loading = true;

            const params = {
                page: this.$route.query.page || 1,
                perPage: this.$route.query.perPage || 100,
                sortBy: this.$route.query.sortBy || 'start_at',
                sortOrder: this.$route.query.sortOrder || 'asc',
                include: 'customer',
                type: 'activity',
                show: this.$route.query.show || null,
                edit: this.$route.query.edit || null,
            };

            if (this.search) {
                params.search = this.search;
            }

            if(this.params.serviceId) {
                params.serviceId = this.params.serviceId;
            }

            if(this.params.startDate) {
                params.startDate = moment(this.params.startDate).format("YYYY-MM-DD");
                params.sortBy = 'start_at'
                params.sortOrder = 'asc'
            }

            if(this.params.status.length > 0) {
                params.status = this.params.status.join(",");
            }

            // First load
            if(initial) {
                // Workaround: If query is the same router will not detect a change on first load
                if(_.isEqual(this.$route.query, params)) {
                    this.loadData();
                }

                const route = Object.assign({}, this.$route);

                this.$router.replace({
                    name: route.name,
                    query: {
                        ...params
                    }
                });

                return;
            }

            this.bookingIndex({
                params,
            }).then((response) => {
                console.log(response);
                if (response.paginator) {
                    this.paginator = response.paginator;
                }
                this.meta = response.meta || {}
                this.ids = response.ids;
            }).finally(() => {
                this.loading = false;
            })
        },
        rescheduleDisabled(booking) {
            return ['started', 'processed', 'no-show', 'canceled'].includes(booking.status);
        },
        focusSearch(e) {
            if (e.ctrlKey && e.keyCode === 70) {
                const search = this.$refs.search
                if (!search) {
                    return
                }
                search.focus()
            }
        }
    },
    destroyed() {
        window.removeEventListener('keydown', this.focusSearch);
    },
    watch: {
        fullPath () {
            this.loadData();
        },
    },
}
</script>
<style lang="scss">
// ToDo See in which component this belongs
.tags {
    & > span {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
    }

    & > a {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
    }
}
// See can we put this to modal CSS
.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.fade-slide-enter-active,
.fade-slide-leave-active {
    transition: all .5s;
}
.fade-slide-enter,
.fade-slide-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
</style>
