<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="c:base-50 flex:|center gap:8 semi mb:8">
          Room
        </div>
        <SelectInput v-model="serviceSpaceId"
                     placeholder="N/A"
                     :options="serviceSpaceOptions"
                     :disabled="disabled || !isQueryValid"
                     @input="handleSpaceSelect"></SelectInput>
      </div>
      <div class="column">
        <div class="c:base-50 flex:|center gap:8 semi mb:8">
          Table
        </div>
        <SelectInput v-model="serviceItemId"
                     placeholder="N/A"
                     :options="serviceItemOptions"
                     :disabled="disabled || !isQueryValid || !serviceSpaceId"></SelectInput>
        <p v-if="autoAssign & autoAssignError" class="help is-danger mt-2">
          There is no available table to assign
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'SpaceItemSelect',
  props: {
    value: {
      type: Number || null,
      required: false,
    },
    query: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    autoAssign: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      serviceSpaceId: null,
      serviceItemId: null,
      loading: false,
      serviceItemIds: [],
      autoAssignError: true
    }
  },
  computed: {
    serviceItems() {
      return this.serviceItemIds ? this.$store.getters['serviceItem/collection'](this.serviceItemIds) : []
    },
    serviceSpaces() {
      let serviceSpaces = [];
      this.serviceItems.forEach(serviceItem => {
        if (serviceItem.serviceSpaceId && !serviceSpaces.find(s => parseInt(s.id) === parseInt(serviceItem.serviceSpaceId))) {
          serviceSpaces = [
            ...serviceSpaces,
            {
              'id': serviceItem.serviceSpaceId,
              'title': serviceItem.serviceSpaceTitle
            }
          ]
        }
      })
      return serviceSpaces;
    },
    serviceItemOptions() {
      return [
        {
          label: "N/A",
          value: null,
        },
        ...this.serviceItems.filter(item => {
          return this.serviceSpaceId && parseInt(this.serviceSpaceId) === parseInt(item.serviceSpaceId);
        }).map(item => {
          return {
            label: item.title,
            value: parseInt(item.id),
            disabled: !item.available
          }
        })
      ]
    },
    serviceSpaceOptions() {
      return [
        {
          label: "N/A",
          value: null,
        },
        ...this.serviceSpaces.map(space => {
          return {
            label: space.title,
            value: parseInt(space.id),
          }
        })
      ]
    },
    isQueryValid() {
      return this.query.serviceId && this.query.quantity && this.query.date && this.query.time && this.query.persons;
    }
  },
  async created() {
    await this.loadServiceItems();
    if (this.value) {
      this.selectServiceItem();
    }
  },
  methods: {
    ...mapActions({
      serviceItemAvailable: 'serviceItem/available',
      serviceSpaceIndex: 'serviceSpace/index'
    }),
    autoAssignServiceItem() {
      const serviceItem = this.serviceItems.find((item) => item.autoAssign);

      if (serviceItem) {
        this.serviceSpaceId = parseInt(serviceItem.serviceSpaceId);
        this.serviceItemId = parseInt(serviceItem.id);
      } else {
        this.autoAssignError = true;
        this.serviceSpaceId = null;
        this.serviceItemId = null;
      }

      this.inputEvent();
    },
    selectServiceItem() {
      const serviceItem = this.serviceItems.find((item) => parseInt(item.id) === this.value);
      this.serviceSpaceId = parseInt(serviceItem.serviceSpaceId);
      this.serviceItemId = parseInt(serviceItem.id);
    },
    deAssignServiceItem() {
      this.serviceSpaceId = null;
      this.serviceItemId = null;
      this.inputEvent();
    },
    loadServiceItems() {
      if (!this.isQueryValid) {
        return false;
      }

      this.loading = true
      return this.serviceItemAvailable({
        params: {
          service_id: this.query.serviceId,
          quantity: this.query.quantity,
          date: this.query.date,
          time: this.query.time,
          persons: this.query.persons,
          update_booking_id: this.query.updateBookingId
        }
      }).then((response) => {
        this.serviceItemIds = response;
        if (this.autoAssign) {
          this.autoAssignServiceItem();
        }
      }).catch((error) => {
      }).finally(() => {
        this.loading = false;
      })
    },
    handleSpaceSelect(value) {
      if(!value) {
        this.serviceItemId = null
      }
    },
    inputEvent() {
      this.$emit('input', this.serviceItemId);
    }
  },
  watch: {
    query() {
      this.autoAssignError = null;

      if (this.isQueryValid) {
        this.loadServiceItems();
      } else {
        this.deAssignServiceItem();
      }
    },
  }
}
</script>

<style scoped lang="scss">
</style>
