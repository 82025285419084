<template>
  <SideDrawer :_class="isEdit ? 'w:960@lg' : 'w:540@lg'" @close="$emit('close')">
    <template v-if="isEdit" slot="header">
      <TabNav
        v-model="activeTab"
        :tabs="[
          ['Overview', 'overview'],
          ['Activity', 'activity'],
        ]"
      />
    </template>

    <div class="p:12|24">
      <div v-if="customer" class="flex:between|center@md p:24 r:8 mb:24 b:base-20 c:accent-70">
        <div>
          <div class="f:32 semi mb:4">{{ customer.firstName }} {{ customer.lastName }}</div>
          <div class="flex:|center gap:12">
            <div class="bg:accent-10 f:14 semi p:4|8 r:4">User #{{ customer.id }}</div>
            <div class="f:16">{{ customer.email }}</div>
          </div>
        </div>
        <div class="flex-col:|end gap:12 mt:16 mt:0@md">
          <div v-if="customer.originId" class="field">
            <div class="bg:accent-10 f:14 semi p:4|8 r:4">{{ customer.originName }} ( {{ customer.originId }} )</div>
          </div>
          <template v-if="customer.user">
            <button @click="handleLoginAs(customer.user)" class="reset f:16 semi c:primary b:base-20 p:10|12 r:8">
              Login As
            </button>
          </template>
        </div>
      </div>

      <OvervewTab v-if="activeTab === 'overview'" ref="overviewTab" />
      <ActivityTab v-if="activeTab === 'activity'" />

    </div>

    <template slot="footer">
      <button
        v-if="customer"
        @click="handleDelete"
        class="reset flex:|center gap:8 f:16 semi c:error py:12 r:8 o:0.6:hover"
      >
        <Icon admin name="trash" />
        Delete
      </button>
      <button
        v-if="customer && customer.userId"
        class="reset flex:|center gap:8 f:16 semi c:primary py:12 r:8 o:0.6:hover"
        @click="handleExportData"
      >
        <Icon admin name="share" />
        Export
      </button>
      <button
        class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover"
        @click="handleSave"
      >
        <Icon admin name="check" />
        Save
      </button>
    </template>
  </SideDrawer>
</template>

<script>
import OvervewTab from './OvervewTab.vue'
import ActivityTab from './ActivityTab.vue'
import SideDrawer from '@/components/admin/SideDrawer.vue'
import TabNav from '@/components/admin/TabNav.vue'
import {mapActions} from "vuex";

export default {
  name: 'CustomerModal',
  components: {
    OvervewTab,
    ActivityTab,
    SideDrawer,
    TabNav,
  },
  data() {
    return {
      activeTab: 'overview',
    }
  },
  computed: {
    isEdit() {
      return this.$route.query.show !== 'create'
    },
    id() {
      return this.isEdit ? this.$route.query.show : null
    },
    customer() {
      return this.id ? this.$store.getters['customer/findBy'](this.id) : null
    },
  },
  methods: {
    ...mapActions({
      userLoginUrl: 'user/loginUrl',
    }),
    handleDelete() {
      this.$refs.overviewTab.handleDelete()
    },
    handleExportData() {
      this.$refs.overviewTab.handleExportData()
    },
    handleSave() {
      this.$refs.overviewTab.handleSave()
    },
    handleLoginAs(user) {
      this.loading = true

      this.userLoginUrl(user.id)
        .then((response) => {
          window.location.href = response.url
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
